.main-div{
    background-color: #181b1f;
    color: white;
    .main-child {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 5px;
        i{
            cursor: pointer;
        }
    }
    
    i{
        margin-right: 10px;
    }
    p{
        color: white;
        padding-left: 5px;
        padding-right: 5px;
        overflow: hidden;
        overflow-wrap: anywhere;
    }
    .log-heading{
        padding: 0;
    }

    .detail-outer{
        padding: 20px;
        .log-details{
            border: 1px solid grey;
            padding: 10px;
            border-radius: 10px;
            .heading{
                font-size: 16px;
            }
        }
    }

    .float-left{
        float: left;
    }

    .form-group{
        padding: 20px;
        .form-control{
            color: #ccccdc;
            background-color: #111217;
            border: 1px solid rgba(204,204,220,.15);

        }
        .form-control:focus {
            border-color: white;
            box-shadow: none;
        }
        .btn{
            margin-top: 30px;
        }
    }
    .results-header{
        text-align: center;
        margin: 20px;
        font-size: 20px;
    }
}