.MainIndex{
    width: 100%;
    padding-right: 70px;

    .data-table-icon{
        font-size: 20px;
        margin-left: 10px;
        cursor: pointer;
    }
    /* Hiding id (1)  in exports servers table  */
    .users-table .mdb-dataTable  thead tr th:nth-child(1)
    {
        display: none;
    }

    .users-table .mdb-dataTable  tbody tr td:nth-child(1)
    {
        display: none;
    }

    .table-btn{
        margin-right: 12px;
    }
}

.main-container{
    .btn-width{
        margin-right: 15px;
    }
    .active-radios{
        margin-top: 10px;
    }
    .margin-left-10{
        margin-left: 10px;
    }  
} 